import BaseTableCol from '@/components/BaseTable/BaseTableCol';
import BaseTable from '@/components/BaseTable/index.vue';
import UploadAvatar from '@/components/UploadAvatar.vue';
import UploadBanner from '@/components/UploadBanner.vue';
import { computed, defineComponent, ref, } from 'vue';
import FormItem from '@/components/FormItem.vue';
import { useI18n } from 'vue-i18n';
import InputField from '@/components/InputField.vue';
import BaseTextarea from '@/components/BaseTextarea.vue';
import Dialog from '@/components/Dialog.vue';
import useDialog from '@/utils/useDialog';
import AddRequirement from './AddRequirement.vue';
/**
 * 发布空投任务-某语言下的表单
 */
export default defineComponent({
    name: 'LangForm',
    components: {
        UploadAvatar,
        UploadBanner,
        BaseTableCol,
        BaseTable,
        FormItem,
        InputField,
        BaseTextarea,
        AddRequirement,
        Dialog,
    },
    props: {
        modelValue: {
            type: Object,
            required: true,
        },
    },
    setup(props) {
        const { t } = useI18n();
        const form = computed(() => props.modelValue); // todo 优化绑定
        const editTarget = ref(undefined);
        const editIndex = ref(0);
        const { isVisible, showDialog, closeDialog } = useDialog();
        const confirmAddHandler = (val) => {
            if (editTarget.value) {
                form.value.requirements[editIndex.value] = val;
                closeDialog();
                editTarget.value = undefined;
                editIndex.value = 0;
            }
            else {
                form.value.requirements.push(val);
                closeDialog();
            }
        };
        const cancelAddHandler = () => {
            editTarget.value = undefined;
            editIndex.value = 0;
            closeDialog();
        };
        const deleteRequirement = (index) => {
            form.value.requirements.splice(index, 1);
        };
        const editRequirement = (row, index) => {
            editTarget.value = row;
            editIndex.value = index;
            showDialog();
        };
        const getForm = () => form;
        return {
            t,
            form,
            isVisible,
            showDialog,
            closeDialog,
            confirmAddHandler,
            cancelAddHandler,
            getForm,
            deleteRequirement,
            editRequirement,
            editTarget,
        };
    },
});
