// 获取是否是选中的日期
const getIsSelected = (target, selectedDate) => {
    if (!selectedDate) {
        return false;
    }
    return target.getDate() === selectedDate.getDate()
        && target.getMonth() === selectedDate.getMonth()
        && target.getFullYear() === selectedDate.getFullYear();
};
/**
 * get date table
 * @param year
 * @param month 正常month-1
 * @param selectedDate 当前选中的Date
 */
export const getTables = (year, month, selectedDate) => {
    const startDate = new Date(year, month);
    const endDate = new Date(Number(new Date(year, month + 1)) - 1);
    // 开始是周日显示在第二行
    const table = [];
    const days = endDate.getDate();
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < days; i++) {
        const item = new Date(year, month, i + 1);
        table.push({
            date: item,
            num: i + 1,
            isSelected: getIsSelected(item, selectedDate),
            isCurrent: true,
        });
    }
    let prefixFlag = startDate.getDay() || 7; // 前面需要用上个月补出几天(星期日补7天)
    const DAY = 24 * 60 * 60 * 1000;
    let prevDateNum = Number(startDate) - DAY;
    while (prefixFlag > 0) {
        const preDate = new Date(prevDateNum);
        table.unshift({
            date: preDate,
            num: preDate.getDate(),
            isSelected: getIsSelected(preDate, selectedDate),
            isCurrent: false,
        });
        prevDateNum -= DAY;
        prefixFlag -= 1;
    }
    const TOTAL = 7 * 6;
    let endFlag = TOTAL - table.length;
    let nextDateNum = Number(endDate) + DAY;
    while (endFlag > 0) {
        const nextDate = new Date(nextDateNum);
        table.push({
            date: nextDate,
            num: nextDate.getDate(),
            isSelected: getIsSelected(nextDate, selectedDate),
            isCurrent: false,
        });
        nextDateNum += DAY;
        endFlag -= 1;
    }
    return table;
};
export const getMonthList = (t) => [
    {
        label: t('january'),
        value: 0,
    },
    {
        label: t('february'),
        value: 1,
    },
    {
        label: t('march'),
        value: 2,
    },
    {
        label: t('april'),
        value: 3,
    },
    {
        label: t('may'),
        value: 4,
    },
    {
        label: t('june'),
        value: 5,
    },
    {
        label: t('july'),
        value: 6,
    },
    {
        label: t('august'),
        value: 7,
    },
    {
        label: t('september'),
        value: 8,
    },
    {
        label: t('october'),
        value: 9,
    },
    {
        label: t('november'),
        value: 10,
    },
    {
        label: t('december'),
        value: 11,
    },
];
