import { computed, defineComponent, onMounted, reactive, toRefs, } from 'vue';
import DialogContainer from '@/components/DialogContainer.vue';
import { useI18n } from 'vue-i18n';
import FormItem from '@/components/FormItem.vue';
import BaseTextarea from '@/components/BaseTextarea.vue';
import InputField from '@/components/InputField.vue';
import RequirementField from './RequirementField.vue';
export default defineComponent({
    name: 'AddRequirement',
    components: {
        DialogContainer,
        FormItem,
        BaseTextarea,
        InputField,
        RequirementField,
    },
    props: {
        defaultTarget: {
            type: Object,
            required: false,
        },
    },
    emits: ['confirm', 'cancel'],
    setup(props, { emit }) {
        const { t } = useI18n();
        const form = reactive({
            id: Math.random(),
            des: '',
            link: '',
            fields: [],
        });
        const isConfirmDisabled = computed(() => {
            if (!form.des) {
                return true;
            }
            return Boolean(form.fields.find((item) => item.title.length === 0));
        }); // fields可不添加
        const isTextExist = computed(() => Boolean(form.fields.find((item) => item.type === 'text')));
        const isImageExist = computed(() => Boolean(form.fields.find((item) => item.type === 'image')));
        onMounted(() => {
            const { defaultTarget } = props;
            if (defaultTarget) {
                form.id = defaultTarget.id;
                form.des = defaultTarget.des;
                form.link = defaultTarget.link;
                form.fields = defaultTarget.fields;
            }
        });
        const addText = () => {
            if (isTextExist.value) {
                return;
            }
            form.fields.push({
                id: `${Math.random()}`,
                title: t('text_title'),
                type: 'text',
            });
        };
        const addImage = () => {
            if (isImageExist.value) {
                return;
            }
            form.fields.push({
                id: `${Math.random()}`,
                title: t('text_title'),
                type: 'image',
            });
        };
        const deleteField = (index) => {
            form.fields.splice(index, 1);
        };
        const confirmAdd = () => {
            emit('confirm', form);
        };
        return {
            t,
            emit,
            ...toRefs(form),
            addText,
            addImage,
            confirmAdd,
            deleteField,
            isConfirmDisabled,
            isTextExist,
            isImageExist,
        };
    },
});
