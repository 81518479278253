import DatePicker from '@/components/DateSelector/DatePicker.vue';
import InputField from '@/components/InputField.vue';
import formatTime from '@/core/utils/formatTime';
import { defineComponent, ref, computed } from 'vue';
import Dropdown from '../Dropdown.vue';
export default defineComponent({
    name: 'DateSelector',
    components: { DatePicker, InputField, Dropdown },
    props: ['modelValue', 'placeholder'],
    setup(props, { emit }) {
        const dropdown = ref(null);
        const label = computed(() => {
            if (!props.modelValue) {
                return '';
            }
            return formatTime(props.modelValue);
        });
        const closePicker = () => {
            if (dropdown.value) {
                dropdown.value.hide();
            }
        };
        const confirmPick = (date) => {
            emit('update:modelValue', date);
            closePicker();
        };
        return {
            confirmPick,
            closePicker,
            label,
            dropdown,
        };
    },
});
