import { ref, computed } from 'vue';
import { getMonthList } from './utils';
const useSelection = (defaultDate, t) => {
    const now = new Date();
    const selectedMonth = ref(defaultDate?.getMonth() || now.getMonth());
    const selectedYear = ref(defaultDate?.getFullYear() || now.getFullYear());
    const monthOptions = computed(() => getMonthList(t));
    const currentYear = now.getFullYear();
    const yearOptions = [
        {
            label: String(currentYear),
            value: currentYear,
        },
        {
            label: String(currentYear + 1),
            value: currentYear + 1,
        },
    ];
    return {
        selectedMonth,
        selectedYear,
        monthOptions,
        yearOptions,
    };
};
export default useSelection;
