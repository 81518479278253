import { defineComponent, computed, ref, onBeforeUnmount, } from 'vue';
/**
 * 用于时间组件下拉选项
 */
export default defineComponent({
    name: 'LightSelect',
    props: {
        modelValue: {
            type: [String, Number],
            required: true,
        },
        options: {
            type: Array,
            required: true,
        },
    },
    setup(props, { emit }) {
        const isUnfold = ref(false);
        const label = computed(() => {
            const target = props.options.find((item) => item.value === props.modelValue);
            return target?.label || '';
        });
        const hide = () => {
            isUnfold.value = false;
            document.removeEventListener('click', hide);
        };
        const show = () => {
            isUnfold.value = true;
            document.addEventListener('click', hide);
        };
        onBeforeUnmount(() => {
            document.removeEventListener('click', hide);
        });
        const select = (target) => {
            emit('update:modelValue', target.value);
            hide();
        };
        const inputClickHanlder = () => {
            if (isUnfold.value) {
                hide();
            }
            else {
                show();
            }
        };
        return {
            label,
            select,
            isUnfold,
            inputClickHanlder,
        };
    },
});
