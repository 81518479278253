import { defineComponent } from 'vue';
import { useI18n } from 'vue-i18n';
/**
 * 表单项组件
 * @desc 包含标签（自动加选填文案），带间距，后期支持表单校验
 */
export default defineComponent({
    name: 'FormItem',
    props: {
        label: {
            type: String,
            required: false,
            default: '',
        },
        optional: {
            // 必填，暂时只用于显示选填文案(无tip时)
            type: Boolean,
            required: false,
            default: false,
        },
        tip: {
            // 跟随在标签后面的灰色文案
            type: String,
            required: false,
            default: '',
        },
        mini: {
            // 用于弹窗内的样式，间距较小,参考添加条件
            type: Boolean,
            required: false,
            default: false,
        },
        space: {
            // 上下间距，默认自带间距
            type: Boolean,
            required: false,
            default: true,
        },
    },
    setup() {
        const { t } = useI18n();
        return { t };
    },
});
