import { defineComponent, nextTick, ref } from 'vue';
export default defineComponent({
    name: 'RequirementField',
    props: {
        modelValue: {
            type: String,
            required: true,
        },
    },
    emits: ['delete', 'update:modelValue'],
    setup(props, { emit }) {
        const isEdit = ref(false);
        const inputDomRef = ref(null);
        const inputHandler = (e) => {
            const target = e.target;
            emit('update:modelValue', target.value);
        };
        const emitDelete = () => {
            emit('delete');
        };
        const showEdit = () => {
            isEdit.value = true;
            nextTick(() => {
                if (inputDomRef.value) {
                    inputDomRef.value.focus();
                }
            });
        };
        return {
            isEdit,
            inputHandler,
            emitDelete,
            inputDomRef,
            showEdit,
        };
    },
});
