import BaseButton from '@/components/BaseButton.vue';
import ButtonGroup from '@/components/ButtonGroup.vue';
import LightSelect from '@/components/LightSelect.vue';
import { defineComponent, computed, reactive, toRefs, } from 'vue';
import { useI18n } from 'vue-i18n';
import useSelection from './useSelection';
import { getTables } from './utils';
const getIsToday = (date) => date.toDateString() === new Date().toDateString();
const getIsBeforeToday = (date) => new Date(date.toDateString()) < new Date(new Date().toDateString());
export default defineComponent({
    name: 'DatePicker',
    components: {
        BaseButton,
        ButtonGroup,
        LightSelect,
    },
    props: ['defaultDate'],
    emits: ['confirm', 'cancel'],
    setup(props, { emit }) {
        const { t } = useI18n();
        // 年月选项和绑定值
        const { selectedMonth, selectedYear, monthOptions, yearOptions, } = useSelection(props.defaultDate, t);
        const state = reactive({
            currentDate: props.defaultDate,
            bindHour: props.defaultDate?.getHours() || '',
            bindMin: props.defaultDate?.getMinutes() || '',
        });
        // 日期表格
        const table = computed(() => getTables(selectedYear.value, selectedMonth.value, state.currentDate));
        // 选中某个日
        const selectDate = (target) => {
            if (getIsBeforeToday(target)) {
                return;
            }
            state.currentDate = new Date(target);
        };
        const confirmHandler = () => {
            if (!state.currentDate) {
                return;
            }
            const result = new Date(state.currentDate);
            result.setHours(state.bindHour);
            result.setMinutes(state.bindMin);
            emit('confirm', result);
        };
        const cancelHandler = () => {
            emit('cancel');
        };
        return {
            t,
            selectedMonth,
            selectedYear,
            monthOptions,
            yearOptions,
            table,
            selectDate,
            ...toRefs(state),
            confirmHandler,
            cancelHandler,
            getIsToday,
            getIsBeforeToday,
        };
    },
});
