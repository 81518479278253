import BaseButton from '@/components/BaseButton.vue';
import BaseRadio from '@/components/BaseRadio.vue';
import BaseTab from '@/components/BaseTab.vue';
import DateSelector from '@/components/DateSelector/index.vue';
import FormItem from '@/components/FormItem.vue';
import StepContainer from '@/components/StepContainer.vue';
import Toast from '@/components/Toast';
import StorageServices from '@/core/Services/StorageServices';
import { TaskStatus } from '@/core/types/Task';
import useLangSelect from '@/utils/useLangSelect';
import { computed, defineComponent, nextTick, onMounted, reactive, toRefs, } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRoute, useRouter } from 'vue-router';
import Dialog from '@/components/Dialog.vue';
import CheckDemo from '@/views/Inc/Batch/CheckDemo.vue';
import useDialog from '@/utils/useDialog';
import EditTask from '@/core/Interactors/EditTask';
import { TaskFormError } from '@/core/types/enums';
import LangForm from './LangForm.vue';
/**
 * 创建/编辑空投任务表单
 * @desc 编辑：url参数带id则为编辑id，从session读取原数据
 */
export default defineComponent({
    name: 'EditTask',
    components: {
        CheckDemo,
        Dialog,
        StepContainer,
        FormItem,
        DateSelector,
        BaseRadio,
        BaseTab,
        LangForm,
        BaseButton,
    },
    setup() {
        const { t } = useI18n();
        const router = useRouter();
        const route = useRoute();
        const { selectedLang, languages } = useLangSelect();
        const form = reactive({
            editId: 0,
            startTime: null,
            endTime: null,
            chain: 'eth',
            infos: languages.map((item) => ({
                lang: item.value,
                banner: '',
                title: '',
                incAvatar: '',
                incName: '',
                detail: '',
                requirements: [],
            })),
        });
        const useLocalState = (id) => {
            const cachedTask = StorageServices.getEditingTask();
            if (!cachedTask) {
                return;
            }
            if (id && cachedTask.id !== id) {
                // 编辑时query id和session id不通
                return;
            }
            if (id) {
                form.editId = id;
            }
            form.startTime = cachedTask.startTime ? new Date(cachedTask.startTime) : null;
            form.endTime = cachedTask.endTime ? new Date(cachedTask.endTime) : null;
            form.chain = cachedTask.chain;
            form.infos = cachedTask.infos;
        };
        onMounted(() => {
            const editId = route.query.id;
            if (editId) {
                useLocalState(Number(editId));
            }
        });
        const handleIsFromPreview = (isFromPreview) => {
            nextTick(() => {
                if (isFromPreview) {
                    useLocalState();
                }
            });
        };
        const steps = computed(() => [
            {
                title: t('task_step1'),
                content: t('task_step1_content'),
            },
            {
                title: t('task_step2'),
                content: t('task_step2_content'),
            },
            {
                title: t('task_step3'),
                content: t('task_step3_content'),
            },
            {
                title: t('task_step4'),
                content: t('task_step4_content'),
            },
        ]);
        const confirmHandler = () => {
            const error = EditTask.getFormInvalid(form);
            if (error !== TaskFormError.NoError) {
                Toast.error(t(error));
                return;
            }
            const task = {
                id: form.editId,
                chain: form.chain,
                startTime: form.startTime?.getTime() || 0,
                endTime: form.endTime?.getTime() || 0,
                status: TaskStatus.NotRelease,
                infos: form.infos,
                applicationCount: 0,
            };
            StorageServices.storeEditingTask(task);
            router.push({
                path: '/inc/batch/preview',
                query: {
                    id: form.editId ? String(form.editId) : undefined,
                },
            });
        };
        const go2Preview = () => {
            const task = {
                id: form.editId,
                chain: form.chain,
                startTime: form.startTime?.getTime() || 0,
                endTime: form.endTime?.getTime() || 0,
                status: TaskStatus.NotRelease,
                infos: form.infos,
                applicationCount: 0,
            };
            StorageServices.storeEditingTask(task);
            router.push({
                path: '/inc/batch/preview',
                query: {
                    id: form.editId ? String(form.editId) : undefined,
                    complete: EditTask.getFormInvalid(form) === TaskFormError.NoError ? '1' : '0',
                },
            });
        };
        const { isVisible: isDemoVisible, closeDialog: closeDemoDialog, showDialog: showDemoDialog } = useDialog();
        return {
            t,
            steps,
            languages,
            ...toRefs(form),
            confirmHandler,
            selectedLang,
            handleIsFromPreview,
            isDemoVisible,
            closeDemoDialog,
            showDemoDialog,
            go2Preview,
        };
    },
    beforeRouteEnter(to, from, next) {
        next((vm) => {
            // eslint-disable-next-line
            vm.handleIsFromPreview(from.path === "/inc/batch/preview");
        });
    },
});
