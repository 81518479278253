import AvatarBox from '@/components/AvatarBox.vue';
import BaseUploader from '@/components/BaseUploader.vue';
import Backend from '@/core/Services/Backend';
import { readFile } from '@/core/utils';
import { defineComponent, ref } from 'vue';
import { useI18n } from 'vue-i18n';
/**
 * 上传banner组件
 * @desc 选取文件后自动上传服务器，绑定的是s3 url
 */
export default defineComponent({
    name: 'UploadAvatar',
    components: {
        AvatarBox,
        BaseUploader,
    },
    props: {
        modelValue: {
            // 绑定的url
            type: String,
            required: true,
        },
    },
    emits: ['update:modelValue'],
    setup(props, { emit }) {
        const { t } = useI18n();
        const base64 = ref('');
        const uploaderRef = ref(null);
        const addHandler = async (file) => {
            try {
                const base64Str = await readFile(file);
                base64.value = base64Str;
                const fileUrl = await Backend.uploadPicture(base64Str, file.name);
                emit('update:modelValue', fileUrl);
            }
            catch (e) {
                base64.value = '';
                emit('update:modelValue', '');
            }
        };
        const reUploader = () => {
            if (!uploaderRef.value) {
                return;
            }
            uploaderRef.value.boxClickHandler();
        };
        return {
            t,
            addHandler,
            base64,
            uploaderRef,
            reUploader,
        };
    },
});
