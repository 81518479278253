import { defineComponent } from 'vue';
export default defineComponent({
    name: 'BaseTextarea',
    props: {
        modelValue: {
            type: String,
            required: true,
        },
        maxlength: {
            type: Number,
            required: false,
        },
    },
    methods: {
        inputHandler(e) {
            const input = e.target;
            this.$emit('update:modelValue', input.value);
            this.$emit('change', input.value);
        },
    },
});
