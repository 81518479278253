import { Lang } from '@/core/types/enums';
import { computed, defineComponent } from 'vue';
import { useI18n } from 'vue-i18n';
export default defineComponent({
    name: 'CheckDemo',
    emits: {
        close: () => true,
    },
    setup(props, { emit }) {
        const { locale } = useI18n();
        const isCn = computed(() => locale.value === Lang.zh);
        const emitClose = () => {
            emit('close');
        };
        return {
            locale,
            isCn,
            emitClose,
        };
    },
});
